import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo } from 'react';
/** google標單填寫預約開戶服務 */
//https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform
export const FromOpenAccount = memo(function FromOpenAccount() {
    return (<classes.container>
      <a href={'https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform'} target={'_blank'} rel='noreferrer' css={css `
          padding: 2px;
          color: #ffffff;
          background-image: linear-gradient(to right, #8f41e9, #578aef);
          border-radius: 2px;
          line-height: 18px;
          transition: 0.3s;
          border: 1px solid #cccccc;
          &:hover {
            background-image: linear-gradient(to left, #cf41e9, #f78aef);
            text-decoration: underline;
          }
        `}>
        開戶服務預約表單
      </a>
    </classes.container>);
});
const classes = {
    container: styled.div `
    border-radius: 4px;
  `,
};
const openAccounURL = 'https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform';
export const FromOpenAccountMantineButton = memo(function FromOpenAccountMantineButton() {
    return (<Button css={css `
          width: 136px;
          height: 40px;
          font-size: 13px;
          padding-right: 0;
          padding-left: 0;
        `} color='gray' variant='gradient' gradient={{ from: 'red', to: 'yellow' }} onClick={() => window.open(openAccounURL, '_blank')}>
        開戶服務預約表單
      </Button>);
});
